import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import { auth } from "../firebase"
import { Container, Row, Col } from "react-bootstrap"
import TitleLine from "../components/utils/TitleLine"
import "../styles/SignUpPage.css"
import NewAsyncButton from "../components/utils/NewAsyncButton.js"
import * as firebaseLogin from "../scripts/login"
import VerifyEmail from "../components/onboarding/VerifyEmail"
import InputCreator from "../components/styledInputs/InputCreator"
import dots from "../images/bgs/dots.svg"

class StudentLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: null,
      Email: "",
      Password: "",
      verifyEmail: false,
    }
    this.login = this.login.bind(this)
  }

  handleForm = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (!user) {
        this.setState({ authenticated: false })
      } else {
        this.setState({ authenticated: true })
      }
    })
  }

  login(resetLoad) {
    //var localState = this.state
    if (!this.state.Email || !this.state.Password) {
      resetLoad("Please fill in all fields. Try Again?")
    } else {
      firebaseLogin
        .default(this.state.Email, this.state.Password)
        .then(() => {
          resetLoad("Logged in")
          let currentUser = auth.currentUser
          if (currentUser.emailVerified) {
            navigate("/student-dashboard/")
          } else {
            this.setState({ verifyEmail: true })
          }
        })
        .catch(error => {
          let message = error.code
          console.log(error.code)
          if (message === "auth/invalid-email") {
            console.log(error)
            resetLoad("Email is invalid")
          } else if (message == "auth/user-not-found") {
            console.log(error)
            resetLoad("User not Found")
          } else if (message == "auth/wrong-password") {
            console.log(error)
            resetLoad("Invalid Password. Try again?")
          } else {
            resetLoad("Error logging in. Try Again?")
          }
        })
    }
  }

  render() {
    let title = "Student Login"
    let description = "Log in to continue volunteering"
    let regularInput = ["Email", "Password"]
    let type = ["text", "password"]
    if (!this.state.verifyEmail) {
      return (
        <Layout image={dots} full>
          <SEO title="Student Login" />
          <Container className="topSpacing justify-content-center">
            <TitleLine title={title} description={description} />
            <ul>
              <Row
                className="justify-content-center sidesPadding"
                style={{ marginTop: "20px" }}
              >
                <InputCreator
                  titles={regularInput}
                  errors={[false, false]}
                  types={type}
                  names={["Email", "Password"]}
                  struct={[1, 1]}
                  callback={this.handleForm}
                  values={{
                    Email: this.state.Email,
                    Password: this.state.Password,
                  }}
                />
              </Row>
            </ul>

            <Row className="center sidesPadding justify-content-center">
              <NewAsyncButton
                buttonTexts={[
                  "Log In",
                  "Logging you in",
                  "Please fill in all fields. Try Again?",
                  "Invalid Email. Try Again?",
                  "Invalid Password. Try Again?",
                  "Error logging in. Try Again?",
                  "Logged In",
                ]}
                buttonVariants={[
                  "primary",
                  "primary",
                  "danger",
                  "danger",
                  "danger",
                  "danger",
                  "success",
                ]}
                buttonColor={["", "", "", "", ""]}
                callback={this.login}
                classname="buttonTop"
              />
            </Row>
            <Row
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
                marginTop: "40px",
              }}
              className="justify-content-center sidesPadding"
            >
              <Col xl={12}>
                <a href="/create-student-account">
                  Don't have an account? Sign up here!
                </a>
              </Col>

              <br></br>
              <Col xl={12}>
                <a href="/SendResetEmail">Forgot your password? Click here.</a>
              </Col>
            </Row>
          </Container>
        </Layout>
      )
    } else {
      return (
        <Layout image={dots}>
          <SEO title="Student Login" />
          <VerifyEmail
            email={this.state.Email}
            password={this.state.Password}
            navigate="/JoinSlack/"
          />
        </Layout>
      )
    }
  }
}

export default StudentLogin
